var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expanded-list rounded-md",class:{
    'bg-white': !_vm.dark,
    'bg-gray-800': _vm.dark
  }},[(_vm.empty === true)?_c('fw-panel-info',{class:['py-2', _vm.emptyCustomClass],attrs:{"centered":"","clean":""}},[_vm._v(_vm._s(_vm.emptyTitle))]):_vm._e(),_vm._t("list"),(_vm.showAddItems)?_c('button',{staticClass:"group cursor-pointer rounded-md w-full p-3",class:_vm.buttonCustomClass,on:{"click":_vm.addElement}},[_c('div',{staticClass:"border group text-gray-500 rounded-md text-sm px-4 py-2 border-dashed font-semibold flex gap-1.5 justify-center items-center",class:{
        'group-hover:border-primary group-hover:text-primary': !_vm.disabled,
        'opacity-50 cursor-not-allowed': _vm.disabled,
        'border-gray-300': !_vm.dark,
        'border-gray-600': _vm.dark
      }},[_c('fw-icon-add',{staticClass:"w-5 text-gray-400",class:{
          'group-hover:text-primary': !_vm.disabled
        }}),_vm._v(" "+_vm._s(_vm.addTitle)+" ")],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }