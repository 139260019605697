<template>
  <VueApexCharts :height="height" :width="width" :type="type" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts
  },

  props: {
    options: {
      type: Object
    },
    type: {
      type: String
    },
    series: {
      type: Array,
      required: true,
      default: () => []
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: 'auto'
    }
  },

  computed: {
    chartOptions() {
      return {
        ...this.options,
        chart: {
          fontFamily: '"Inter Variable", Roboto, system-ui, sans-serif',
          locales: [
            {
              name: 'pt',
              options: {
                months: [
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro'
                ],
                shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                days: [
                  'Domingo',
                  'Segunda-feira',
                  'Terça-feira',
                  'Quarta-feira',
                  'Quinta-feira',
                  'Sexta-feira',
                  'Sábado'
                ],
                shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                toolbar: {
                  download: 'Download SVG',
                  selection: 'Selection',
                  selectionZoom: 'Selection Zoom',
                  zoomIn: 'Zoom In',
                  zoomOut: 'Zoom Out',
                  pan: 'Panning',
                  reset: 'Reset Zoom'
                }
              }
            }
          ],
          defaultLocale: 'pt',
          animations: {
            enabled: false
          },
          ...this.options.chart,
          toolbar:
            this.options?.chart?.toolbar?.show === true
              ? {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download:
                      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"></path></svg>',
                    selection: false,
                    zoom: true,
                    zoomin:
                      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748ZM10 10V7H12V10H15V12H12V15H10V12H7V10H10Z"></path></svg>',
                    zoomout:
                      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748ZM7 10H15V12H7V10Z"></path></svg>',
                    pan: false,
                    reset:
                      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M22 12C22 17.5228 17.5229 22 12 22C6.4772 22 2 17.5228 2 12C2 6.47715 6.4772 2 12 2V4C7.5817 4 4 7.58172 4 12C4 16.4183 7.5817 20 12 20C16.4183 20 20 16.4183 20 12C20 9.53614 18.8862 7.33243 17.1346 5.86492L15 8V2L21 2L18.5535 4.44656C20.6649 6.28002 22 8.9841 22 12Z"></path></svg>'
                  },
                  autoSelected: 'zoom'
                }
              : {
                  show: false
                }
        }
      }
    }
  }
}
</script>

<style>
.apexcharts-tooltip {
  font-family: 'Inter Variable', Roboto, system-ui, sans-serif !important;
}

.apexcharts-tooltip-title {
  background-color: white !important;
}

.apexcharts-tooltip {
  background-color: white !important;
}

.apexcharts-toolbar {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

.apexcharts-zoomin-icon {
  transform: scale(0.85) !important;
}

.apexcharts-zoomout-icon {
  transform: scale(0.85) !important;
}

.apexcharts-menu-icon {
  transform: scale(0.85) !important;
}

.apexcharts-reset-icon {
  margin-left: 0px !important;
  transform: scale(0.8) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apexcharts-zoom-icon {
  display: none !important;
}
</style>
