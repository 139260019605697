<template>
  <div
    class="expanded-list rounded-md"
    :class="{
      'bg-white': !dark,
      'bg-gray-800': dark
    }"
  >
    <fw-panel-info v-if="empty === true" centered clean :class="['py-2', emptyCustomClass]">{{
      emptyTitle
    }}</fw-panel-info>
    <slot name="list"></slot>
    <button
      v-if="showAddItems"
      class="group cursor-pointer rounded-md w-full p-3"
      :class="buttonCustomClass"
      @click="addElement"
    >
      <div
        class="border group text-gray-500 rounded-md text-sm px-4 py-2 border-dashed  font-semibold flex gap-1.5 justify-center items-center"
        :class="{
          'group-hover:border-primary group-hover:text-primary': !disabled,
          'opacity-50 cursor-not-allowed': disabled,
          'border-gray-300': !dark,
          'border-gray-600': dark
        }"
      >
        <fw-icon-add
          class="w-5 text-gray-400"
          :class="{
            'group-hover:text-primary': !disabled
          }"
        ></fw-icon-add>
        {{ addTitle }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ExpandList',
  props: {
    addTitle: {
      type: String,
      default: 'Adicionar'
    },
    empty: {
      type: Boolean
    },
    emptyTitle: {
      type: String,
      default: 'Sem registos'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emptyCustomClass: {
      type: String
    },
    showAddItems: {
      type: Boolean,
      default: true
    },
    buttonCustomClass: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addElement() {
      if (this.disabled) return
      this.$emit('add-element')
    }
  }
}
</script>

<style scoped></style>
