<template>
  <div class="flex flex-col justify-center bg-gray-800 p-3 md:p-5 rounded-2xl gap-3 md:gap-5 max-w-3xl group w-full">
    <div class="relative">
      <div
        v-show="hasCameraPermissions == true && startWithCamera"
        class="flex justify-center relative bg-black rounded-xl aspect-w-16 aspect-h-9 overflow-hidden"
      >
        <video
          id="video_camara"
          ref="video_camara"
          autoplay
          playsinline
          class="w-full object-cover"
          :class="{ 'transform-gpu': !camera.mirrorDisabled }"
          :style="{ transform: !camera.mirrorDisabled ? 'rotateY(180deg)' : 'none' }"
        ></video>
      </div>
      <div
        v-show="hasCameraPermissions == false || hasCameraPermissions == null || !startWithCamera"
        class="bg-black relative rounded-3xl w-full aspect-w-16 aspect-h-9 overflow-hidden"
      >
        <div class="text-white text-lg font-semibold flex flex-col items-center justify-center gap-5">
          <div>
            <fw-icon-user class="w-14 h-14 md:h-24 md:w-24 text-gray-700 block" />
          </div>
          <div v-if="isPermissionDevicesDenied || isPermissionDevicesDismissed">
            <div class="text-gray-300 text-sm lg:text-base max-w-sm mx-auto text-center">
              Esta aplicação {{ isPermissionDevicesDismissed ? 'parece não estar' : 'não está' }} autorizada a utilizar
              o seu microfone e/ou a sua câmara.
            </div>
            <div v-if="isPermissionDevicesDismissed" class="flex justify-center">
              <fw-button type="link" @click.native="listDevices">Autorizar microfone e câmara</fw-button>
            </div>
            <div v-else class="text-gray-400 hidden md:flex text-xs lg:text-sm max-w-sm mx-auto text-center">
              Aceda às definições deste browser para validar as permissões de utilização dos seus dispositivos de som e
              vídeo nesta aplicação.
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasCameraPermissions == true && startWithCamera"
        class="flex absolute top-0 left-0 w-full justify-between gap-5 p-2"
      >
        <div>
          <fw-button v-if="false" type="link" size="sm" class="-mt-1" @click.native="$emit('toggle-mirror-video')">
            <fw-icon-camera-switch class="w-6 h-6 flex-shrink-0" />
          </fw-button>
        </div>
        <div v-if="cameraQuality == 'HD' || cameraQuality == 'FHD'">
          <div
            class="hidden md:flex border-primary uppercase text-primary font-bold border-2 rounded-lg px-2 py-1.5 text-sm"
          >
            {{ cameraQuality }}
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10">
      <div
        v-if="startWithAudio"
        class="hidden md:flex gap-1 rounded-full items-center justify-center bg-gray-900 px-5 py-1"
      >
        <div
          class="rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.6}%` }"
        ></div>
        <div
          class="hidden md:flex rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.4}%` }"
        ></div>
        <div
          class="hidden md:flex rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.2}%` }"
        ></div>
        <div
          class="rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1}%` }"
        ></div>
        <div
          class="hidden md:flex rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.2}%` }"
        ></div>
        <div
          class="hidden md:flex rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.4}%` }"
        ></div>
        <div
          class="rounded w-1.5 min-h-1.5 max-h-8"
          :class="{
            'bg-primary/80': hasMicrophonePermissions == true,
            'bg-gray-600': !hasMicrophonePermissions
          }"
          :style="{ height: `${microphoneVolume / 1.6}%` }"
        ></div>
      </div>

      <div v-else class="hidden md:flex gap-1 rounded-full items-center justify-center bg-gray-900 px-5 py-1">
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
        <div class="bg-gray-600 rounded w-1.5 min-h-1.5 max-h-8"></div>
      </div>

      <div class="flex gap-3 md:gap-5 items-center justify-center">
        <fw-button
          :type="startWithAudio && hasMicrophonePermissions === true ? 'primary' : 'danger'"
          size="xl"
          custom-class="rounded-full w-[5rem] flex justify-center"
          @click.native="toggle('audio')"
        >
          <fw-icon-mic-line v-if="startWithAudio && hasMicrophonePermissions === true" class="h-5 w-5" />
          <fw-icon-mic-off v-else class="h-5 w-5" />
        </fw-button>
        <fw-button
          :type="startWithCamera && hasCameraPermissions === true ? 'primary' : 'danger'"
          size="xl"
          custom-class="rounded-full w-[5rem] flex justify-center"
          @click.native="toggle('camera')"
        >
          <fw-icon-vidicon v-if="startWithCamera && hasCameraPermissions === true" class="h-5 w-5" />
          <fw-icon-camera-off v-else class="h-5 w-5" />
        </fw-button>
      </div>

      <div class="hidden md:flex gap-1 rounded-full items-center bg-gray-900 justify-between px-5 py-1">
        <fw-button :type="cameraQuality == 'SD' ? 'link' : 'link-muted'" @click.native="changeVideoQuality('SD')"
          >SD</fw-button
        >
        <fw-button :type="cameraQuality == 'HD' ? 'link' : 'link-muted'" @click.native="changeVideoQuality('HD')"
          >HD</fw-button
        >
        <fw-button
          v-if="debugMode"
          :type="cameraQuality == 'FHD' ? 'link' : 'link-muted'"
          class="hidden lg:flex"
          @click.native="changeVideoQuality('FHD')"
          >FHD</fw-button
        >
      </div>
    </div>

    <div class="flex flex-col gap-1 border-t border-gray-700 pt-2">
      <div v-if="mediaSettingsOpen" class="flex md:hidden items-center justify-center">
        <fw-button type="link-muted" size="xs" @click.native="mediaSettingsOpen = !mediaSettingsOpen"
          >Definições</fw-button
        >
      </div>
      <div
        class="md:grid md:grid-cols-3 gap-2 md:gap-5 justify-start opacity-95"
        :class="{ hidden: mediaSettingsOpen, 'flex flex-col': !mediaSettingsOpen }"
      >
        <div>
          <div>
            <fw-label marginless size="xs">Microfone</fw-label>
          </div>
          <b-dropdown
            v-if="hasMicrophonePermissions == true"
            max-height="300"
            aria-role="list"
            position="is-bottom-right"
          >
            <fw-button
              slot="trigger"
              :aria-label="audioInputDevice ? audioInputDevice.label : $t('selectaudiodevice')"
              type="transparent"
              icon="mic-line"
              icon-right="chevron-down"
              size="xs"
              class="text-gray-200 bg-gray-700 bg-opacity-50"
            >
              <span class="line-clamp-1">{{
                audioInputDevice ? audioInputDevice.label : $t('selectaudiodevice')
              }}</span>
            </fw-button>

            <b-dropdown-item
              v-for="device in availableAudioInput"
              :key="device.deviceId"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-button type="transparent" size="sm" expanded @click.native="selectMicrophone(device)">
                <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                  <div
                    class="h-2 w-2 rounded-full"
                    :class="{
                      'bg-gray-200': audioInputDevice && audioInputDevice.deviceId !== device.deviceId,
                      'bg-primary': audioInputDevice && audioInputDevice.deviceId === device.deviceId
                    }"
                  ></div>
                  <span class="line-clamp-1">{{ device.label }}</span>
                </div>
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
          <div v-else class="text-red-500 text-sm p-0.5 flex flex-col gap-1 min-h-7">
            Sem permissões para utilizar
          </div>
        </div>
        <div>
          <div>
            <fw-label marginless size="xs">Câmara</fw-label>
          </div>
          <b-dropdown v-if="hasCameraPermissions == true" max-height="300" aria-role="list" position="is-bottom-right">
            <fw-button
              slot="trigger"
              :aria-label="videoInputDevice ? videoInputDevice.label : $t('selectvideodevice')"
              type="transparent"
              icon="vidicon"
              icon-right="chevron-down"
              size="xs"
              class="text-gray-200 bg-gray-700 bg-opacity-50"
            >
              <span class="line-clamp-1">{{
                videoInputDevice ? videoInputDevice.label : $t('selectvideodevice')
              }}</span>
            </fw-button>

            <b-dropdown-item
              v-for="device in availableVideoInput"
              :key="device.deviceId"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-button type="transparent" size="sm" expanded @click.native="selectVideo(device)">
                <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                  <div
                    class="h-2 w-2 rounded-full"
                    :class="{
                      'bg-gray-200': videoInputDevice && videoInputDevice.deviceId !== device.deviceId,
                      'bg-primary': videoInputDevice && videoInputDevice.deviceId === device.deviceId
                    }"
                  ></div>
                  {{ device.label }}
                </div>
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
          <div v-else class="text-red-500 text-sm p-0.5 flex flex-col gap-1 min-h-7">
            Sem permissões para utilizar
          </div>
        </div>
        <div>
          <div>
            <fw-label marginless size="xs">Saída de som</fw-label>
          </div>
          <b-dropdown
            v-if="hasMicrophonePermissions == true"
            max-height="300"
            aria-role="list"
            position="is-bottom-right"
          >
            <fw-button
              slot="trigger"
              :aria-label="audioOutputDevice ? audioOutputDevice.label : $t('selectoutputdevice')"
              type="transparent"
              icon="volume-lines"
              icon-right="chevron-down"
              size="xs"
              class="text-gray-200 bg-gray-700 bg-opacity-50"
            >
              <span class="line-clamp-1">{{
                audioOutputDevice ? audioOutputDevice.label : $t('selectoutputdevice')
              }}</span>
            </fw-button>

            <b-dropdown-item
              v-for="device in availableAudioOutput"
              :key="device.deviceId"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-button type="transparent" expanded size="sm" @click.native="selectOutput(device)">
                <div class="inline-flex gap-2 items-center justify-start w-full whitespace-nowrap">
                  <div
                    class="h-2 w-2 rounded-full"
                    :class="{
                      'bg-gray-200': audioOutputDevice && audioOutputDevice.deviceId !== device.deviceId,
                      'bg-primary': audioOutputDevice && audioOutputDevice.deviceId === device.deviceId
                    }"
                  ></div>
                  {{ device.label }}
                </div>
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
          <div v-else class="text-gray-500 text-sm p-0.5 flex flex-col gap-1 min-h-7">
            <div>Não definido.</div>
          </div>
        </div>
      </div>
      <div
        v-if="isPermissionDevicesDenied || isPermissionDevicesDismissed"
        class="hidden md:flex text-gray-400 text-xs gap-2 py-1 pr-2"
      >
        <div>
          <fw-icon-info class="h-5 w-5 inline-block flex-shrink-0" />
        </div>
        <div>
          <div>
            O seu microfone e/ou câmara não estão autorizados a ser utilizados nesta página. Verifique as permissões nas
            definições deste browser / navegador. Depois de autorizar, pode escolher entrar na sessão com a câmara e/ou
            microfone desligados.
          </div>
          <div>
            Em alguns dispositivos, pode não ser possível selecionar a saída de som quando o microfone não está
            autorizado.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

export default {
  name: 'BlockVideoAudioTest',

  props: {
    meeting: {
      type: Object,
      default: null
    },
    startWithAudio: {
      type: Boolean,
      default: true
    },
    startWithCamera: {
      type: Boolean,
      default: true
    },
    audio: {
      type: Object,
      default: null
    },
    camera: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      hasCameraPermissions: null,
      hasMicrophonePermissions: null,
      availableVideoInput: [],
      availableAudioInput: [],
      availableAudioOutput: [],
      audioInputDevice: null,
      audioOutputDevice: null,
      videoInputDevice: null,
      microphoneVolume: 0,
      speaking: false,
      analyser: null,
      audioStream: null,
      videoStream: null,
      initialStream: null,
      audioAllowed: false,
      cameraAllowed: false,
      cameraQuality: 'HD',
      debugMode: localStorage.getItem('fw-debug') === 'true',
      mediaSettingsOpen: !this.isMobile,
      error: {
        audio: null,
        video: null,
        devices: null,
        streams: null
      }
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },

    isPermissionDevicesDismissed() {
      return this.error.devices === 'NotAllowedError: Permission dismissed'
    },
    isPermissionDevicesDenied() {
      return !this.isPermissionDevicesDismissed && this.error?.devices?.includes('NotAllowedError')
    }
  },

  async mounted() {
    if (localStorage.getItem('video_quality')) {
      this.cameraQuality = localStorage.getItem('video_quality')
    } else {
      localStorage.setItem('video_quality', 'HD')
      localStorage.setItem('device.video.hd', true)
    }

    // Check browser permissions for video and mic
    await this.checkPermissions()

    this.listDevices()

    this.audioAllowed = this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'audio_allowed')
    this.cameraAllowed = this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'camera_allowed')
  },

  destroyed() {
    this.stopStream('audio')
    this.stopStream('camera')
  },

  methods: {
    changeVideoQuality(quality) {
      this.cameraQuality = quality
      localStorage.setItem('video_quality', quality)
      if (quality === 'SD') {
        localStorage.setItem('device.video.hd', false)
      } else if (quality === 'FHD') {
        localStorage.setItem('device.video.hd', true)
        localStorage.setItem('device.video.fhd', true)
      } else {
        localStorage.setItem('device.video.hd', true)
      }
    },

    stopStream(type) {
      // Force stop the initial stream
      if (this.initialStream) {
        console.log('Stopping initial stream', this.initialStream)
        this.initialStream.getTracks().forEach(track => track.stop())
        this.initialStream = null
      }

      if (type === 'audio' && this.audioStream) {
        console.log('Stopping audio stream', this.audioStream)
        this.audioStream.getTracks().forEach(track => track.stop())
        this.audioStream = null
      }
      if (type === 'camera' && this.videoStream) {
        console.log('Stopping camera stream', this.videoStream)
        const video = this.$refs.video_camara
        if (video) {
          let stream = video.srcObject
          if (stream instanceof MediaStream) {
            console.log('MediaStream active on video element:', stream)
            stream.getTracks().forEach(track => {
              track.stop()
            })
            stream = null
          }
        }

        this.videoStream.getTracks().forEach(track => track.stop())
        this.videoStream = null

        this.$emit('toggle-video-start-option')
      }
    },

    toggle(type) {
      // Stop the microphone
      if (type === 'audio') {
        if (this.audioStream) {
          this.stopStream('audio')
        } else {
          this.createAudioStream()
        }
        this.$emit('toggle-audio-start-option')
      }

      // Camera
      else if (type === 'camera') {
        if (this.videoStream) {
          this.stopStream('camera')
        } else {
          this.createCameraStream()
        }
        this.$emit('toggle-camera-start-option')
      }
    },

    askPermission(type) {
      if (type === 'camera') {
        this.createCameraStream()
      } else if (type === 'microphone') {
        this.createAudioStream()
      }
    },

    async checkPermissions(microfone = true, camera = true) {
      try {
        if (microfone) {
          // Check camera permission
          const cameraPermission = await navigator.permissions.query({ name: 'camera' })
          console.log('Camera Permission:', cameraPermission.state) // "granted", "denied", or "prompt"
          if (cameraPermission.state === 'granted') {
            this.hasCameraPermissions = true
          } else if (cameraPermission.state === 'prompt') {
            this.hasCameraPermissions = null
          } else {
            //it was denied
            this.hasCameraPermissions = false
          }
        }
        if (camera) {
          // Check microphone permission
          const microphonePermission = await navigator.permissions.query({ name: 'microphone' })
          console.log('Microphone Permission:', microphonePermission.state) // "granted", "denied", or "prompt"
          if (microphonePermission.state === 'granted') {
            this.hasMicrophonePermissions = true
          } else if (microphonePermission.state === 'prompt') {
            this.hasMicrophonePermissions = null
          } else {
            //it was denied
            this.hasMicrophonePermissions = false
          }
        }
      } catch (error) {
        console.error('Error checking permissions:', error)
      }
    },

    async createCameraStream() {
      // Make sure we stop any previous stream
      this.stopStream('camera')

      // Append the video stream to the video element
      const videoElement = this.$refs.video_camara
      const constraints = {
        video: true
      }

      if (this.videoInputDevice !== null) {
        constraints.video = {
          deviceId: this.videoInputDevice.deviceId
        }
      }

      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia(constraints)
        videoElement.srcObject = this.videoStream
        console.log('Video stream started:', this.videoStream)
      } catch (error) {
        console.error('Error accessing the camera: ', error)
        this.error.video = error.toString()
      }
    },

    async createAudioStream() {
      this.stopStream('audio')

      try {
        // Get the audio stream from the user's microphone, use the selected device
        const constraints = {
          audio: true
        }
        if (this.audioInputDevice !== null) {
          constraints.audio = {
            deviceId: this.audioInputDevice.deviceId
          }
        }
        this.audioStream = await navigator.mediaDevices.getUserMedia(constraints)
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const source = audioContext.createMediaStreamSource(this.audioStream)

        // Create an AnalyserNode to analyze the audio data
        this.analyser = audioContext.createAnalyser()
        this.analyser.fftSize = 256 // Smaller FFT size for quicker detection
        source.connect(this.analyser)

        // Start detecting speech
        this.detectSpeech()
      } catch (error) {
        console.error('Error accessing audio devices.', error)
        this.error.audio = error.toString()
      }
    },

    detectSpeech() {
      // Data array to hold audio analysis data
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount)

      // Get the audio data
      this.analyser.getByteFrequencyData(dataArray)

      // Calculate the average volume
      const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length

      // Set a threshold for detecting speech
      const threshold = 40 // Adjust this value based on your environment

      this.microphoneVolume = averageVolume * 1.25

      // Change the indicator color based on speech detection
      if (averageVolume > threshold) {
        this.speaking = true
      } else {
        this.speaking = false
      }
      // Continue analyzing in the next animation frame
      requestAnimationFrame(this.detectSpeech)
    },

    selectVideo(device) {
      this.videoInputDevice = device
      localStorage.setItem('device.video.input', device.deviceId)
      if (this.camera) {
        this.camera.setDevice(device.deviceId)
      }
      // Create stream with selected device to display animation
      this.createCameraStream()
    },

    selectMicrophone(device) {
      this.audioInputDevice = device
      localStorage.setItem('device.audio.input', device.deviceId)
      if (this.audio) {
        this.audio.setDevice(device.deviceId)
      }
      //create stream with selected device to display animation
      this.createAudioStream()
    },

    selectOutput(device) {
      this.audioOutputDevice = device
      localStorage.setItem('device.audio.output', device.deviceId)
    },

    isGetUserMediaAvailable() {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
    },

    listDevices() {
      let self = this
      console.log('=== List devices')

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(respo => {
          this.initialStream = respo
          console.log('=== getUserMedia success', this.initialStream)

          navigator.mediaDevices.enumerateDevices().then(devices => {
            console.log('Available devices: ', devices)
            devices.forEach(device => {
              if (device.kind === 'videoinput') {
                self.availableVideoInput.push(device)
              } else if (device.kind === 'audioinput') {
                self.availableAudioInput.push(device)
              } else if (device.kind === 'audiooutput') {
                self.availableAudioOutput.push(device)
              }
            })

            let found = null
            if (self.availableVideoInput.length > 0) {
              this.hasCameraPermissions = true
              if (localStorage.getItem('device.video.input')) {
                found = self.availableVideoInput.find(
                  device => device.deviceId === localStorage.getItem('device.video.input')
                )
                if (found) {
                  self.videoInputDevice = found
                } else {
                  self.videoInputDevice = self.availableVideoInput[0]
                }
              } else {
                self.videoInputDevice = self.availableVideoInput[0]
              }
            }

            if (self.availableAudioInput.length > 0) {
              this.hasMicrophonePermissions = true
              if (localStorage.getItem('device.audio.input')) {
                found = self.availableAudioInput.find(
                  device => device.deviceId === localStorage.getItem('device.audio.input')
                )
                if (found) {
                  self.audioInputDevice = found
                } else {
                  self.audioInputDevice = self.availableAudioInput[0]
                }
              } else {
                self.audioInputDevice = self.availableAudioInput[0]
              }
            }

            if (self.availableAudioOutput.length > 0) {
              if (localStorage.getItem('device.audio.output')) {
                found = self.availableAudioOutput.find(
                  device => device.deviceId === localStorage.getItem('device.audio.output')
                )
                if (found) {
                  self.audioOutputDevice = found
                } else {
                  self.audioOutputDevice = self.availableAudioOutput[0]
                }
              } else {
                self.audioOutputDevice = self.availableAudioOutput[0]
              }
            }

            try {
              // Start audio stream
              self.createAudioStream()
              // Start video stream
              self.createCameraStream()
            } catch (error) {
              console.error('Error starting streams: ', error)
              this.error.streams = error.toString()
            }
          })
        })
        .catch(error => {
          console.error('Error listing devices: ', error)
          this.error.devices = error.toString()
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "allowcameramessage": "You need to allow the camera to see the video",
    "activatecamerabutton": "Activate camera",
    "selectaudiodevice": "Select audio device",
    "selectoutputdevice": "Select output device",
    "selectvideodevice": "Select video device"
  },
  "es": {
    "allowcameramessage": "Necesitas permitir la cámara para ver el video",
    "activatecamerabutton": "Activar cámara",
    "selectaudiodevice": "Seleccionar dispositivo de audio",
    "selectoutputdevice": "Seleccionar dispositivo de saída",
    "selectvideodevice": "Seleccionar dispositivo de vídeo"
  }
}
</i18n>
