<template>
  <fw-panel :title="$t('weeklyView')" :counter="weekLabel" class="mt-10" :loading="loading">
    <template v-slot:toolbar>
      <next-previous-buttons
        :next-text="$t('next')"
        :has-next="hasNext"
        :has-previous="selectedWeekNumber > 0"
        :has-reset="selectedWeekNumber !== currentWeekNumber"
        show-reset
        @next="nexWeek"
        @previous="previousWeek"
        @reset="resetToCurrentWeek"
      ></next-previous-buttons>
    </template>
    <div class="relative">
      <WeekView
        :class="{ 'opacity-50': loading }"
        :events="selectedWeekEvents"
        :is-current-week="selectedWeekNumber === currentWeekNumber"
        :selected-week-number="selectedWeekNumber"
        :show-all-dates="showAllDates"
        :show-weekend="showWeekend"
        :selected-year="year"
      >
        <template #events="{ events }">
          <slot :events="events" name="events"></slot>
        </template>
      </WeekView>
    </div>
  </fw-panel>
</template>

<script>
import WeekView from '@/fw-modules/fw-core-vue/ui/components/panels/WeekView.vue'
import NextPreviousButtons from '@/fw-modules/fw-core-vue/ui/components/buttons/NextPreviousButtons.vue'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'WeeklyEventsPanel',
  components: {
    WeekView,
    NextPreviousButtons
  },

  props: {
    weeks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    showWeekend: {
      type: Boolean,
      default: false
    },
    showAllDates: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      currentWeekNumber: 1,
      selectedWeekNumber: -1,
      selectedWeekDate: Dates.now(),
      hasNext: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    }
  },

  computed: {
    weekLabel() {
      return `${this.year}/W${this.selectedWeekNumber} ${
        this.selectedWeekNumber === this.currentWeekNumber ? this.$t('current') : ''
      }`
    },

    selectedWeekEvents() {
      return this.selectedWeekNumber && this.weeks[`${this.selectedWeekNumber}`]
        ? this.weeks[`${this.selectedWeekNumber}`].events
        : []
    }
  },

  beforeMount() {
    // Get first current (now) week to allow reset
    this.currentWeekNumber = this.getWeekNumber()
    this.selectedWeekDate = Dates.now()
    this.month = this.selectedWeekDate.month()
    this.year = this.selectedWeekDate.year()

    this.getWeekEvents()
  },

  methods: {
    getWeekNumber(date = null) {
      let currentDate = date ? Dates.build(date.toISOString()) : Dates.build(new Date().toISOString())
      return currentDate.isoWeek()
    },

    nexWeek() {
      this.selectedWeekDate = this.selectedWeekDate.add(7, 'day')

      if (this.year != this.selectedWeekDate.year()) {
        this.year = this.selectedWeekDate.year()
      }

      this.selectedWeekNumber = this.getWeekNumber(this.selectedWeekDate.toDate())
      this.getWeekEvents(this.selectedWeekDate.year())
    },

    previousWeek() {
      this.selectedWeekDate = this.selectedWeekDate.subtract(7, 'day')

      if (this.year != this.selectedWeekDate.year()) {
        this.year = this.selectedWeekDate.year()
      }
      this.selectedWeekNumber = this.getWeekNumber(this.selectedWeekDate.toDate())

      this.getWeekEvents()
    },

    resetToCurrentWeek() {
      this.selectedWeekNumber = this.currentWeekNumber
      this.selectedWeekDate = Dates.now()
      this.year = this.selectedWeekDate.year()
      this.getWeekEvents()
    },

    async getWeekEvents(year = null) {
      let today = Dates.now()

      if (!year) {
        year = today.year()
      }

      if (this.selectedWeekNumber == -1) {
        this.selectedWeekNumber = this.getWeekNumber()
      }

      if (this.selectedWeekNumber == 1 && today.month() > 0) {
        year = year + 1
      }

      if (!(this.selectedWeekNumber.toString() in this.weeks)) {
        this.$emit('get-week-events', this.selectedWeekNumber, year)
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "weeklyView": "Vista semanal",
    "next": "Próxima",
    "current": "(atual)"
  },
  "en": {
    "weeklyView": "Weekly view",
    "next": "Next",
    "current": "(current)"
  }
}
</i18n>
