<template>
  <fw-tag :type="type" :size="size" :custom-class="customClass">
    <slot v-if="$slots['default']" name="default"></slot>
    <div v-else>{{ text }}</div>
    <button
      v-if="showCloseButton"
      role="button"
      class="transparent font-semibold text-sm focus:outline-none hover:bg-gray-400 hover:bg-opacity-10 relative"
      @click="$emit('close')"
    >
      <fw-icon-close class="w-5 h-5" />
    </button>
  </fw-tag>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    type: {
      type: String,
      default: 'medium'
    },
    customClass: String
  }
}
</script>
